
import { defineComponent, ref, inject, onMounted } from "vue";
import axios from "@/api/axios";
import api from "@/api/axios";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import { useRouter, useRoute } from "vue-router";
import $ from "jquery";
export default defineComponent({
  name: "WechatRed",
  data() {
    return {
      Message: '',
      showRed:false,
      price:5.6,
      rushList: [
        {uid: '133**9873', pid: '5.88'},
      ],
      isGetLog:false
    }
  },
  setup(){
    const route = useRoute();
    let code = route.query.code as string
    let state = route.query.state as string
    const courseId = route.query.courseId
    const aLucky = ref(true)
    const isLuck = ref()
    const visiblePic = ref(false)
    const showModal = ref(false);

    const href = window.location.href
    const codeReg = /code=(.*?)&/
    const stateReg = /state=(.*)/

    if (href.match(codeReg)) {
        code = RegExp.$1
    }
    if (href.match(stateReg)) {
        state = RegExp.$1
    }
    const router = useRouter();
    const arr = [1,2,3,4,5,6,7,8];
    let i = 0;//转到哪个位置
    let count= 0;//转圈初始值
    const totalCount = 3;//转动的总圈数
    let speed = 300;//转圈速度，越大越慢
    const minSpeed = 300;
    let timer: any = null;
    let isClick = false;
    let index = 3;//指定转到哪个奖品
    const text = ref("100元代金券");//奖品
    const activeNum = ref(0);
    const imgUrl = ref("https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png");
    const imgStyle = ref({
      width: "1rem",
      left: "1.46rem",
      top: "1.5rem",
    });
    const imgUrlArr = [
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png",
        text: "100元代金券"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "100元红包"
      },
      {
        img:  "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
        text: "168积分"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "2.88元红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/cash_20020210113.png",
        text: "200元代金券"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "66.88元红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
        text: "888积分"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "10.00元红包"
      },
    ]
    const imgArr = [
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/cash_20020210113.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        }
    ]
    const dialogShow = ref(false);
    let that: any = null;
    const redId = [
      {
        id: 1,
        index: 1
      },
      {
        id: 2,
        index: 2
      },
      {
        id: 3,
        index: 3
      },
      {
        id: 4,
        index: 4
      },
      {
        id: 5,
        index: 5
      },
      {
        id: 6,
        index: 6
      },
      {
        id: 7,
        index: 7
      },
      {
        id: 8,
        index: 8
      },
    ]
    const redPrice = ref()

    function roll(){
        //速度衰减
        speed -= 50;
        if(speed<=10){
            speed=10;
        }
        //每次调用都去掉全部active类名
        activeNum.value = 10;
        i++;
        //计算转圈次数
        if(i >= 8){
            i = 0;
            count++;
        }
        activeNum.value = i;
        //满足转圈数和指定位置就停止
        if (count >= totalCount && (i + 1) == index){
            clearTimeout(timer);
            speed = minSpeed;
            text.value = imgUrlArr[i].text;
            imgUrl.value = imgUrlArr[i].img;
            isClick = true;
            for (let j = 0; j < imgArr.length; j++) {
                if (imgArr[j].img == imgUrl.value) {
                  imgStyle.value = {
                    width: imgArr[j].width,
                    left: imgArr[j].left,
                    top: imgArr[j].top,
                  }
                }
            }
            setTimeout(function () {
              showModal.value = true;
            }, 1200)
        }else{
            timer = setTimeout(roll,speed);//不满足条件时调用定时器
            //最后一圈减速
            if(count >= totalCount-1 || speed <= 50){
                speed += 100;
            }
        }
    }
    function closeModal() {
      showModal.value = false
    }

    function handleDialog(){
      dialogShow.value = false;
    } 
    function handleLuckDraw() {
      that=this;
      that.rushList=[];
      //只请求一次红包接口
      if(!that.isGetLog) {
        api.post('/M/CourseServer/doOrderingRedPacket',
            qs.stringify({
              code,
              state,
              courseId,
            })
        ).then((res) => {
          const data = res.data
          if (data.success) {
            that.showRed = true;
            that.isGetLog=true;
            if (data.msg == 'noFollow') {
              Toast({
                title: '您还未关注BNCC微信公众号，请先关注再抽奖！',
                type: "error",
                duration: 2000
              });
              visiblePic.value = true
              return false
            }

            redId.forEach(item => {
              if (item.id == data.data.rewardNum) {
                index = item.index
              }
            })
            // aLucky.value = false
            isLuck.value = 'yes'
            roll();
            redPrice.value = data.link
            //显示对应的金额
            this.price = data.link;
          } else {
            //没有抽成功显示msg
            that.showRed = false;
            that.Message = data.msg;
            showModal.value = true;
          }
        })
            .catch((err) => {
              console.log(err);
            });
      }
        //获取记录
        api.post("/M/CourseServer/getOrderingRedPacketRecord", qs.stringify({courseId})).then((res) => {
          that.rushList=[];
          that.rushList = res.data.data;
        }).catch((err) => {
          Toast({
            type: "error",
            title: err
          });
        });
      }

    function repeatClick() {
      that=this;
      showModal.value=true;
      api.post('/M/CourseServer/getOrderingRedPacketRecord',
          qs.stringify({
            courseId,
          })
      ).then((res) => {
        that.rushList=[];
        const data = res.data;
        showModal.value=true;
        if (data.success) {
          that.rushList = data.data;
          that.showRed=true
          that.Message=data.msg;
          // this.price=data.link;
          console.log(data,'repeatClick',that.rushList)
        } else {
          that.showRed=false

        }
      })
          .catch((err) => {
            console.log(err);
          });
      
    }

    function closePic() {
      visiblePic.value = false
    }

    onMounted(() => {
      $('.wheelOfFortune_wrap').height(($(window) as any).height()-70)
    })
    function back() {
      router.go(-1);
    }

    return {
      text,
      imgUrl,
      handleLuckDraw,
      imgStyle,
      activeNum,
      dialogShow,
      handleDialog,
      redPrice,
      back,
      aLucky,
      repeatClick,
      visiblePic,
      closePic,
      showModal,
      closeModal
    }
  }
});
